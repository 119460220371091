import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  padding: ${props => props.paddingTop ? '7rem 0 3rem 0' : '0 0 3rem 0'};
  color: ${props => props.theme.colours[props.fg]};
  background-color: ${props => props.theme.colours[props.bg]};
  min-height: 100vh;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: ${props => props.paddingTop ? '5rem 0 3rem 0' : '0 0 3rem 0'};
  }
`;

function PageHolder({children, transitionFrom, bg, fg, paddingTop}) {

    return (
        <Holder className={`content-holder from-${transitionFrom}`} bg={bg} fg={fg} paddingTop={paddingTop}>
            {children}
        </Holder>
    )
}

PageHolder.propTypes = {
    transitionFrom: PropTypes.oneOf(['bottom', 'left', 'right']),
    bg: PropTypes.oneOf(['cream', 'grey', 'peach', 'maroon']),
    fg: PropTypes.oneOf(['green', 'blue', 'red', 'black', 'cream']),
    paddingTop: PropTypes.bool,
};

PageHolder.defaultProps = {
    transitionFrom: 'bottom',
    bg: 'cream',
    fg: 'black',
    paddingTop: true,
};

export default PageHolder;
