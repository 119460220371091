import React from 'react';
import styled from "styled-components";

const Holder = styled.div`
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 9fr 1.5fr;
  }

  .margined {
    padding-left: 6rem;
    //padding-top: 2rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      padding: 0;
    }
  }

  .image-grid {
    display: grid;
    grid-template-rows: auto auto;
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      grid-row: unset;
      grid-template-columns: 4fr 2.4fr;
    }

    .gatsby-image-wrapper {
      margin-bottom: 2rem;
      margin-left: -1rem;
      max-width: 75%;
      @media ( ${props => props.theme.breakpoints.lg} ) {
        margin: 0;
      }
    }

    svg {
      display: inline-block;
      grid-column: 1;
      margin-top: 0.5rem;
      width: 2.2rem;
      height: auto;
      @media ( ${props => props.theme.breakpoints.lg} ) {
        width: unset;
        height: unset;
      }
    }

    .rich-text-holder, p {
      ${props => props.theme.fluidType(-1.6)};
      @media ( ${props => props.theme.breakpoints.lg} ) {
        ${props => props.theme.fluidType(-0.75)};
      }
    }

    p + h4 {
      margin-top: 2rem;
    }

    .quote {
      font-family: 'Eliza', Helvetica, Arial, sans-serif;
      font-style: italic;
      grid-column: 1/ -1;
      line-height: 1.2;
      margin: 0;
    }
  }
`;

function WeirdTextGrid({children}) {
    return (
        <Holder>{children}</Holder>
    );
}

export default WeirdTextGrid;