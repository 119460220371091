import React, {useEffect, useState} from 'react';
import {graphql, Link} from 'gatsby';
import PageHolder from '../components/organisms/PageHolder';
import SEO from '../components/molecules/SEO';
import {GatsbyImage} from 'gatsby-plugin-image';
import Container from '../components/atoms/Container';
import styled from 'styled-components';
import RichTextConverter from "../components/atoms/RichTextConverter";
import {useWindowSize} from "react-use";
import Motif from '../assets/motif.inline.svg'
import WeirdTextGrid from "../components/organisms/WeirdTextGrid";
import WeirdListHolder from "../components/organisms/WeirdListHolder";
import WeirdDetailsHolder from "../components/organisms/WeirdDetailsHolder";
import LeftArrow from '../assets/left-arrow.inline.svg'
import {useStore} from "../utils/store";

const ArrowBanner = styled.div`
  position: absolute;
  z-index: 20;
  top: calc(${props => props.height} + 1.75rem);

  svg {
    height: 1rem;
    width: auto;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      height: unset;
      width: unset;
    }

    path {
      fill: ${props => props.theme.colours.peach};
    }
  }
`

const Heading = styled.div`
  h1 {
    ${props => props.theme.fluidType(4)};
    margin: 1rem 0;
    line-height: 1;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(3)};
      margin: 4rem 0 0 0;
    }
  }
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  line-height: 2;
  font-family: 'Eliza Mono', Helvetica, Arial, sans-serif;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    gap: 4rem;
  }

  div {
    p {
      margin: 0;
    }

    p:first-child {
      margin: 0 0 0.5rem 0;
    }
  }
`;

const ContentHolder = styled.div`
  display: flex;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    column-gap: 1rem;
    align-content: start;
    grid-template-columns: 1fr 4.5fr;
  }
`;

const BannerImage = styled.div`
  margin: 2rem 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

const ImageHolder = styled.div`
  grid-row: 4/5;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: 1/6;
    grid-row: auto;
  }

  .gatsby-image-wrapper {
    height: 19rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      max-height: 18.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  margin: 3rem 0;
  ${props => props.theme.fluidType(-1.6)};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    ${props => props.theme.fluidType(-0.75)};
  }
`

const MobileImage = styled.div`
  padding-bottom: 2rem;
  margin-left: -1rem;
  max-width: 75%;
`

function Articles({data}) {
    const post = data.contentfulJournal;
    const [mobile, setMobile] = useState(null)
    const size = useWindowSize()
    const height = useStore(state => state.height)

    useEffect(() => {
        if (size.width < 768) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [size.width, setMobile]);

    return (
        <PageHolder bg="cream" paddingTop={false}>
            <SEO title={post.title}/>
            <Container><ArrowBanner height={height}><Link to="/journal"><LeftArrow/></Link></ArrowBanner></Container>
            <ImageHolder>
                <GatsbyImage
                    alt={post.title}
                    layout="fullWidth"
                    image={post.alternateSinglePageFeaturedImage ? post.alternateSinglePageFeaturedImage.gatsbyImageData : post.featuredImage.gatsbyImageData}/>
            </ImageHolder>
            <Container>
                <Heading>
                    <h1 className="h2 eliza-mono">{post.title}</h1>
                </Heading>
                <WeirdDetailsHolder>
                    <Details>
                        {post.location &&
                            <div><p>Location:</p><p>{post.location}</p></div>
                        }
                        {post.date &&
                            <div><p>Date:</p><p>{post.date}</p></div>
                        }
                        {post.source &&
                            <div><p>Source:</p><p>{post.source}</p></div>
                        }
                        {post.section &&
                            <div><p>Section:</p><p>{post.section}</p></div>
                        }
                    </Details>
                </WeirdDetailsHolder>
                {!mobile &&
                    <>
                        <ContentHolder>
                            <WeirdListHolder>
                                {post.authors && <>
                                    <p>Author[s]:</p>
                                    <ul>{post.authors.map(author =>
                                        <li key={author}>{author}</li>)}</ul>
                                </>}
                            </WeirdListHolder>
                            <WeirdTextGrid>
                                <div className="margined">
                                    {post.headline && <RichTextConverter content={post.headline}/>}
                                </div>
                            </WeirdTextGrid>
                        </ContentHolder>
                        <ContentHolder>
                            <div></div>
                            <WeirdTextGrid>
                                {post.text &&
                                    <div className="image-grid">
                                        <div className="margined">
                                            <RichTextConverter content={post.text}/>
                                            {(!post.text2 && !post.text3 && !post.text4 && !post.text5) && <Motif/>}
                                        </div>
                                        {post.images && <GatsbyImage alt='Featured image'
                                                                     image={post.images[0].gatsbyImageData}/>}
                                    </div>
                                }
                            </WeirdTextGrid>
                        </ContentHolder>
                    </>
                }
                {mobile &&
                    <>
                        {post.headline && <RichTextConverter content={post.headline}/>}
                        <Grid>
                            <WeirdListHolder>
                                {post.authors && <div>
                                    <p>Author[s]:</p>
                                    <ul>{post.authors.map(author =>
                                        <li key={author}>{author}</li>)}</ul>
                                </div>}
                            </WeirdListHolder>
                            {post.text &&
                                <div className="image-grid">
                                    <div className="margined">
                                        <RichTextConverter content={post.text}/>
                                        {(!post.text2 && !post.text3 && !post.text4 && !post.text5) && <Motif/>}
                                    </div>
                                </div>
                            }
                        </Grid>
                        <MobileImage>
                            {post.images && <GatsbyImage alt='Featured image'
                                                         image={post.images[0].gatsbyImageData}/>}
                        </MobileImage>
                    </>
                }
            </Container>
            {!mobile &&
                <BannerImage>
                    {post.image1 && <GatsbyImage
                        alt={post.title}
                        layout="fullWidth"
                        image={post.image1.gatsbyImageData}/>}
                </BannerImage>
            }
            {post.text2 &&
                <Container>
                    <ContentHolder>
                        <div></div>
                        <WeirdTextGrid>
                            <div className="image-grid">
                                <div className="margined">
                                    <RichTextConverter content={post.text2}/>
                                </div>
                                <div className="quote">
                                    <RichTextConverter content={post.quote}/>
                                    {(!post.text3 && !post.text4 && !post.text5) && <Motif/>}
                                </div>

                            </div>
                        </WeirdTextGrid>
                    </ContentHolder>
                </Container>}
            {mobile &&
                <BannerImage>
                    {post.image1 && <GatsbyImage
                        alt={post.title}
                        layout="fullWidth"
                        image={post.image1.gatsbyImageData}/>}
                </BannerImage>
            }
            {post.text3 &&
                <Container>
                    <ContentHolder>
                        <div></div>
                        <WeirdTextGrid>
                            <div className="image-grid">
                                <div className="margined">
                                    <RichTextConverter content={post.text3}/>
                                    {(!post.text4 && !post.text5) && <Motif/>}
                                </div>
                                {post.images && <GatsbyImage alt='Featured image'
                                                             image={post.images[1].gatsbyImageData}/>}
                            </div>
                        </WeirdTextGrid>
                    </ContentHolder>
                </Container>
            }
            {(post.featuredImage2 && !mobile) && <BannerImage>
                <GatsbyImage
                    alt={post.title}
                    layout="fullWidth"
                    image={post.featuredImage2.gatsbyImageData}/>
            </BannerImage>}
            {post.text4 && <Container>
                <ContentHolder>
                    <div>
                    </div>
                    <WeirdTextGrid>
                        <div className="image-grid">
                            <div className="margined">
                                <RichTextConverter content={post.text4}/>
                                {!post.text5 && <Motif/>}
                            </div>
                        </div>
                    </WeirdTextGrid>
                </ContentHolder>
            </Container>}
            {post.featuredImage3 && <BannerImage>
                <GatsbyImage
                    alt={post.title}
                    layout="fullWidth"
                    image={post.featuredImage3.gatsbyImageData}/>
            </BannerImage>}
            {post.text5 && <Container>
                <ContentHolder>
                    <div>
                    </div>
                    <WeirdTextGrid>
                        <div className="image-grid">
                            <div className="margined">
                                <RichTextConverter content={post.text5}/>
                                <Motif/>
                            </div>
                        </div>
                    </WeirdTextGrid>
                </ContentHolder>
            </Container>}
        </PageHolder>
    )
}

export default Articles;

export const articlesPageQuery = graphql`
    query($id: String!) {
        contentfulJournal(id: {eq: $id}) {
            id
            slug
            title
            section
            date(formatString: "DD•MM•YY")
            authors
            source
            location
            featuredImage {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            image1 {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            featuredImage2 {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            featuredImage3 {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            alternateSinglePageFeaturedImage {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
            headline {
                raw
            }
            text {
                raw
            }
            text2 {
                raw
            }
            quote {
                raw
            }
            text3 {
                raw
            }
            text4 {
                raw
            }
            text5 {
                raw
            }
            images {
                id
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }
        }
    }
`;